import {Col, Container, Row} from 'react-bootstrap'
import {DomainSliderListParagraph, Paragraph} from '../../../types/paragraphs'
import {Response} from '../../../types/api-types'

import Slider from './Slider'
import ContentTitles from '../../shared/ContentTitles'

type Props = {
  paragraph: Paragraph
  response: Response | null
}

const DomainSlider = ({paragraph, response}: Props) => {
  const domainSliderData: DomainSliderListParagraph | undefined =
    response?.included?.find(el => el.id === paragraph.id)

  const domainSliderItemsData: DomainSliderListParagraph[] | undefined =
    response?.included?.filter(el =>
      domainSliderData?.relationships.field_domain_slider_domains.data.find(
        sd => sd.id === el.id,
      ),
    )

  if (!domainSliderData) {
    return null
  }

  if (!domainSliderItemsData) {
    return null
  }

  return (
    <section className="domain-slider overflow-hidden section--bg-grey flex">
      <Container fluid className="section-box">
        <Row>
          <Col lg={5}>
            <div className="domain-slider__header">
              {domainSliderData.attributes.field_title ||
              domainSliderData.attributes.field_toptitle ? (
                <ContentTitles
                  title={domainSliderData.attributes.field_title}
                  toptitle={domainSliderData.attributes.field_toptitle}
                  isToptitleCustomColor={false}
                />
              ) : null}
              <span className="d-block mb-3">
                {domainSliderData.attributes.field_text_long}
              </span>
            </div>
          </Col>
        </Row>
        <Slider
          domainSliderItemsData={domainSliderItemsData}
          response={response}
        />
      </Container>
    </section>
  )
}

export default DomainSlider
