import React, {useEffect, useLayoutEffect, useRef, useState} from 'react'
import {Col, Row} from 'react-bootstrap'
import MediaItem from '../../../shared/MediaItem'
import {getElementDimensions, WindowDimentions} from '../../../../utils/hooks'
import {Response} from '../../../../types/api-types'
import {DomainSliderListParagraph} from '../../../../types/paragraphs'
import qs from 'qs'
import {useTranslation} from 'next-i18next'
import striptags from 'striptags'
import Link from 'next/link'

type SliderProps = {
  response: Response | null
  domainSliderItemsData: Array<DomainSliderListParagraph>
}

const Slider = ({domainSliderItemsData, response}: SliderProps) => {
  const {t} = useTranslation()
  const sliderRef = useRef<HTMLUListElement>(null)
  const scrollLeft = useRef(0)
  const [activeIndex, setIndex] = useState<number>(0)
  const [dimensions, setDimensions] = useState<WindowDimentions>({
    width: 0,
    height: 0,
  })

  function setSliderPosition() {
    if (sliderRef.current !== null) {
      sliderRef.current.scrollLeft = scrollLeft.current
    }
  }

  useLayoutEffect(() => {
    setDimensions(getElementDimensions(sliderRef))
  }, [])

  useEffect(() => {
    if (dimensions.width && dimensions.width > 922) {
      scrollLeft.current = activeIndex * (dimensions.width * 0.2)
    } else if (dimensions.width && dimensions.width < 922) {
      scrollLeft.current = activeIndex * (dimensions.width * 0.8)
    }

    setSliderPosition()

    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      })
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [activeIndex, dimensions, domainSliderItemsData])

  function filterByDomainLink(domainName: string) {
    return qs.stringify({
      refinementList: {
        field_domain: [domainName],
      },
      page: 1,
      configure: {
        filters: 'type:vacancy',
        hitsPerPage: 30,
        query: '',
        aroundRadius: 40000,
        minimumAroundRadius: 40000,
        aroundLatLng: '',
      },
    })
  }

  function linkHandler(domainName: string, urlButton: string) {
    if (domainName) {
      return `/${striptags(t('job_detail_slug')).replace(
        /\r\n/g,
        '',
      )}?${filterByDomainLink(domainName)}`
    }

    if (urlButton) {
      return `${urlButton}`
    }

    return `#`
  }

  return (
    <Row>
      <Col>
        <div className="domain-slider__carousel-wrapper">
          <div className="domain_slider__carousel__controls position-absolute w-100 h-100">
            {activeIndex === 0 ? null : (
              <button
                className="domain_slider__carousel__controls__prev d-none d-md-block position-absolute border-0"
                aria-label="Previous slide"
                onClick={() => {
                  setIndex(activeIndex - 4)
                }}
              ></button>
            )}
            {activeIndex + 4 >= domainSliderItemsData.length ? null : (
              <button
                className="domain_slider__carousel__controls__next d-none d-md-block position-absolute border-0 right"
                aria-label="Next slide"
                onClick={() => {
                  setIndex(activeIndex + 4)
                }}
              ></button>
            )}
          </div>
          <ul
            ref={sliderRef}
            className="domain-slider__carousel d-flex  mt-3   list-unstyled w-100 h-100 d-flex"
          >
            {domainSliderItemsData?.map(item => {
              const image = response?.included?.find(
                el => el.id === item.relationships.field_media?.data?.id,
              )

              const domainName = response?.included?.find(
                el => el.id === item.relationships.field_domain?.data?.id,
              )

              return (
                <li
                  className="domain-slider__carousel__item  position-relative p-3 d-flex justify-content-end flex-column overflow-hidden"
                  key={item.id}
                >
                  <Link
                    href={linkHandler(
                      domainName?.attributes.name,
                      item.attributes.field_button?.uri?.alias,
                    )}
                  >
                    <a
                      target={
                        domainName
                          ? '_self'
                          : item.attributes.field_button?.options.attributes
                              .target
                      }
                    >
                      <div className="domain-slider__carousel__item__image">
                        <MediaItem
                          objectFit="cover"
                          layout="fill"
                          response={response}
                          item={image}
                        ></MediaItem>
                      </div>
                      <div className="domain-slider__carousel__item__content position-relative">
                        <h5 className="font-weight-light text-yellow">
                          {item.attributes.field_title}
                        </h5>
                        <span className="text-white">
                          {item.attributes.field_text_long}
                        </span>
                      </div>
                    </a>
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
      </Col>
    </Row>
  )
}

export default Slider
